.simple-page {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: #E6E6E6;
}
.logo {
  display: block;
  animation: rotate 2s linear infinite;
}
@keyframes rotate {
  0% {
    transform: rotateZ(0deg);
    /*从0度开始*/
  }

  100% {
    transform: rotateZ(360deg);
    /*360度结束*/
  }
}